// Note: This example requires that you consent to location sharing when
// prompted by your browser. If you see the error "The Geolocation service
// failed.", it means you probably did not give permission for the browser to
// locate you.
let map, infoWindow, limits, markers;
var pets = [];
var pOrder = [];
markers = [];


function initMap() {
	map = new google.maps.Map(document.getElementById("homepage_map"), {
		center: { lat: 32.7205172, lng: -114.6074127 },
		zoom: 14,
		styles: mapStyles
	});
	infoWindow = new google.maps.InfoWindow();
 	limits = new google.maps.LatLngBounds();

	google.maps.event.addListener(map, 'click', function(event) {
	  if (infoWindow) {
	    infoWindow.close();
	    $('.location').removeClass('active');
	  }
	  map.fitBounds(limits);
	});

	google.maps.event.addListener(infoWindow, 'closeclick', function(event){
	  map.fitBounds(limits);
	});


}

function handleLocationError(browserHasGeolocation, infoWindow, pos) {
	$('.loading_msg').hide();
	infoWindow.setPosition(pos);
	infoWindow.setContent(
		browserHasGeolocation
			? "Error: The Geolocation service failed."
			: "Error: Your browser doesn't support geolocation."
	);
	infoWindow.open(map);
}

window.initMap = initMap;

jQuery(function($){ //on document.ready

	$.when(	petTypesGet(), petStatusesGet(), petsGet() ).then(function() {
		$('.loading_msg').hide();
		$.each(pets, function (i,p){
			if (p) {
				const myLatLng = new google.maps.LatLng(p.loc_lat, p.loc_lng);
				//console.log(myLatLng);
				let icon = petIcons[p.pet_type];
				if (p.approved == 0) {
					icon.fillColor = '#ff0000';
				} else {
					icon.fillColor = (p.status == 6 ? '#2079e2' : '#18a04f');
				}
				icon.strokeColor = icon.fillColor;
				icon.anchor = new google.maps.Point(192,512);
				//console.log(icon);
				let marker = new google.maps.Marker({
					icon: icon,
					position: myLatLng,
					map,
					title: p.name,
				});

				markers.push(marker);

				google.maps.event.addListener(marker, 'click', function(){
					var pet = $('#pet-listing-proto').clone().removeAttr('id').show();

					pet.attr('id', 'pet_' + p.pet_id);
					pet.data('pet_id', p.pet_id);
					pet.find('.view_pet').data('pet_id', p.pet_id);
					pet.find('.pet_type').text(pet_types[p.pet_type].name);
					pet.find('.pet_name').text(p.name);
					pet.find('.pet_color').html(p.color);
					pet.find('.pet_description').html(p.description);
					pet.find('.pet_collar').text(p.collar == 1 ? 'YES' : 'NO');
					pet.find('.pet_status').text(pet_statuses[p.status].name);
					pet.find('.lost_date').text(moment(p.created).format("MMM Do YYYY"));
					if (p.photos.length) {
						let aif = '';
						$.each(p.photos, function(i,pho){
							if (pho.pp_id == p.highlight_photo) {
								aif = pho.filename;
							}
						});
						if (aif) {
							pet.find('img').attr('src','/art/pet_photos/130sq/' + aif);
						}
					} else {
						pet.find('img').hide();
					}
					pet.find('.view_pet').data('pet_id', p.pet_id);
					infoWindow.setContent($(pet)[0]);
        			infoWindow.open(map, marker);
				});

			   	limits.extend(marker.position);
				map.fitBounds(limits);
			}
		})
		new markerClusterer.MarkerClusterer({ markers, map });
	});


	$(document).on('click', '.view_pet', function(e){
		e.preventDefault();
		window.location.href = '/pet/' + $(this).data('pet_id');
	})

	$(document).on('click', '.map_to_me', function (e) {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					};
					map.setCenter(pos);
					map.setZoom(18);
				},
				() => {
					handleLocationError(true, infoWindow, map.getCenter());
				}
			);
		} else {
			// Browser doesn't support Geolocation
			handleLocationError(false, infoWindow, map.getCenter());
		}
	});
});